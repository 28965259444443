import { gql } from '@apollo/client';
// import { gql } from 'graphql-request';

export const GetPosts = gql`
  query ($filter: PostListFilter, $sort: PostListSort, $pagination: PaginationInput) {
    posts(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        id
        title
        content
        user {
          # id
          # email
          nickname
          avatar
        }
        commentCount
        likeCount
        myLike
        category {
          id
          title
        }
        images {
          id
          url
        }
        tags
        createdAt
        updatedAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GetBestPosts = gql`
  query ($filter: PostListFilter, $sort: PostListSort, $pagination: PaginationInput) {
    bestPosts(filter: $filter, sort: $sort, pagination: $pagination) {
      data {
        id
        title
        content
        user {
          # id
          # email
          nickname
          avatar
        }
        commentCount
        likeCount
        myLike
        category {
          id
          title
        }
        images {
          id
          url
        }
        tags
        createdAt
        updatedAt
      }
      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GetPost = gql`
  query ($id: Int!, $pagination: PaginationInput) {
    post(id: $id) {
      nextPost {
        id
      }
      prevPost {
        id
      }
      id
      title
      content
      user {
        # id
        # email
        nickname
        avatar
      }
      commentCount
      likeCount
      myLike
      category {
        id
        title
      }
      images {
        id
        url
      }
      tags
      createdAt
      updatedAt
      comments(pagination: $pagination) {
        data {
          id
          comment
          createdAt
          updatedAt
          likeCount
          myLike
          parent
          user {
            id
            email
            nickname
            avatar
          }
          nestedComments(pagination: { perPage: 3 }) {
            data {
              id
              comment
              createdAt
              updatedAt
              likeCount
              myLike
              parent
              user {
                id
                email
                nickname
              }
            }
            pagination {
              total
              perPage
              currentPage
              lastPage
            }
          }
        }
        pagination {
          total
          perPage
          currentPage
          lastPage
        }
      }
    }
  }
`;

export const GetNestedReply = gql`
  query ($parentId: ID!, $pagination: PaginationInput) {
    postNestedComments(parentId: $parentId, pagination: $pagination) {
      __typename
      data {
        __typename
        id
        comment
        createdAt
        updatedAt
        likeCount
        myLike
        parent
        user {
          __typename
          # id
          # email
          nickname
        }
      }
      pagination {
        __typename
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const GetPostCategories = gql`
  query {
    postCategories {
      id
      title
    }
  }
`;

export const CreatePost = gql`
  mutation ($input: CreatePostInput!) {
    createPost(input: $input) {
      id
      title
      content
      user {
        id
        email
        nickname
      }
      commentCount
      likeCount
      myLike
    }
  }
`;

export const TogglePostLike = gql`
  mutation ($postId: Int!) {
    togglePostLike(postId: $postId) {
      ok
    }
  }
`;

export const CreatePostComment = gql`
  mutation ($input: CreateCommentInput!) {
    createPostComment(input: $input) {
      id
      comment
      createdAt
      updatedAt
      likeCount
      myLike
      parent
      user {
        id
        email
        nickname
        avatar
      }
      nestedComments {
        data {
          id
          comment
          createdAt
          updatedAt
          likeCount
          myLike
          parent
        }
        pagination {
          total
          perPage
          currentPage
          lastPage
        }
      }
    }
  }
`;

export const TogglePostCommentLike = gql`
  mutation ($commentId: Int!) {
    togglePostCommentLike(commentId: $commentId) {
      ok
    }
  }
`;

export const UpdateUser = gql`
  mutation ($user: UserUpdateInput!) {
    updateUser(user: $user) {
      id
      nickname
    }
  }
`;

export const SignS3 = gql`
  mutation ($bucket: String, $filename: String!, $filetype: String!) {
    signS3(bucket: $bucket, filename: $filename, filetype: $filetype) {
      signedRequest
      url
    }
  }
`;

export const PostsWithTag = gql`
  query ($input: PostsWithTag, $pagination: PaginationInput) {
    postsWithTag(input: $input, pagination: $pagination) {
      data {
        id
        title
        content
        user {
          # id
          # email
          nickname
          avatar
        }
        commentCount
        likeCount
        # myLike
        category {
          id
          title
        }
        images {
          id
          url
        }
        tags
        createdAt
        updatedAt
      }

      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const PostsWithTagName = gql`
  query ($input: PostsWithTag, $pagination: PaginationInput) {
    postsWithTagName(input: $input, pagination: $pagination) {
      data {
        id
        title
        content
        user {
          # id
          # email
          nickname
          avatar
        }
        comments {
          data {
            id
            comment
            user {
              nickname
              avatar
            }
          }
        }

        commentCount
        likeCount
        # myLike
        # category {
        #  id
        #  title
        #}
        images {
          id
          url
        }
        tags
        createdAt
        updatedAt
      }

      pagination {
        total
        perPage
        currentPage
        lastPage
      }
    }
  }
`;

export const PostsWithTagByEventId = gql`
  query ($input: PostsWithTagByEventId) {
    postsWithTagByEventId(input: $input) {
      id
      title
      content
      userId
      avatar
      nickname
      likeCount
      commentCount
      tags
      images {
        url
      }
      comments {
        id
        comment
      }
    }
  }
`;

export const MyPostActivity = gql`
  query {
    userActivity {
      posts
      comments
      likes
      share
    }
  }
`;

export const MyPosts = gql`
  query {
    userPosts {
      id
      title
      commentCount
      likeCount
      createdAt
    }
  }
`;

export const MyComments = gql`
  query {
    userComments {
      id
      comment
      postId
      createdAt
      likeCount
    }
  }
`;

export const MyLikes = gql`
  query {
    userLikes {
      id
      title
      commentCount
      likeCount
      createdAt
      author
    }
  }
`;
