import styled from 'styled-components';
import Link from 'next/link';
import Image from 'next/image';
import { memo } from 'react';

import { PC_MENU } from './menuData';

function LayoutLeftTemplate() {
  return (
    <section className="pl-[5rem]">
      <Link href="/philing">
        <div className="w-[13rem] mb-[4rem] cursor-pointer">
          <Image
            src="/assets/images/icons/main_logo_03.svg"
            alt="언에이징"
            width={130}
            height={130}
            priority
          />
        </div>
      </Link>
      <p className={`text-5xl/1.2 mt-[0.5rem]`}>
        오늘보다 내일
        <br />더 젊어보이는 이유,
        <br />
        <span className="font-bold">{`'젊어지는' 비밀을 풀다`}</span>
      </p>

      <div className="my-[4rem]">
        {PC_MENU.map((row, idx) => (
          <div className="flex mb-[1.5rem]" key={idx}>
            {row.map(({ label, link }) => (
              <Link key={link} href={link}>
                <Tag>{label}</Tag>
              </Link>
            ))}
          </div>
        ))}
      </div>
      {/* <ApplyPhilingCard /> */}
    </section>
  );
}

export default memo(LayoutLeftTemplate);

const Tag = styled.div`
  font-size: 2rem;
  background-color: #e1ea68;
  border-radius: 2rem;
  padding: 1rem 2rem;
  margin-right: 1rem;
  cursor: pointer;
  &:active {
    transform: translateY(0.3rem);
  }
`;
